import React, { useEffect, useState } from "react";
import "./App.css";

const circlesPerRow = 52;
const totalRows = 80;

function App() {
  const [aliveWeeks, setAliveWeeks] = useState(0);
  const [remaingWeeks, setRemaningWeeks] = useState(0);

  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint
    fetch("http://3.133.175.93:8000/v1/users/?username=temp1")
	  
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response contains the number of alive weeks
        setAliveWeeks(data.data.weeks_alive || 0);
        setRemaningWeeks(data.data.remaining_weeks || 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const filledCircles = aliveWeeks;

  useEffect(() => {
    drawCanvas(filledCircles);
  }, [filledCircles]);

  function drawCanvas(filledCircles) {
    const canvas = document.getElementById("circleCanvas");
    const ctx = canvas.getContext("2d");

    const circleSize = 20;
    const margin = 1;

    for (let row = 0; row < totalRows; row++) {
      for (let col = 0; col < circlesPerRow; col++) {
        const circleIndex = row * circlesPerRow + col;
        const isFilled = circleIndex < filledCircles;

        const x = col * (circleSize + margin);
        const y = row * (circleSize + margin);

        ctx.beginPath();
        ctx.arc(
          x + circleSize / 2,
          y + circleSize / 2,
          circleSize / 2,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = isFilled ? "#880808" : "#9d9d9d ";
        ctx.strokeStyle = "black";
        ctx.lineWidth = 1;

        if (!isFilled) {
          ctx.stroke();
        }

        ctx.fill();
        ctx.closePath();
      }
    }
  }

  return (
    <div className="App">
      <div className="content">
        <div className="left-section">
          <h1> MOMENTO MORI </h1>
          <p>WEEKS ALIVE: {aliveWeeks}</p>
          <p>REMINING WEEKS: {remaingWeeks}</p>
        </div>
        <canvas
          id="circleCanvas"
          width={circlesPerRow * 22}
          height={totalRows * 22}
        ></canvas>
      </div>
    </div>
  );
}

export default App;
