// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("static/fonts/Constantine.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("static/images/marcus.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@font-face {
  font-family: 'ChiRho';
  src: local('ChiRho'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
body {
  margin: 0;
  font-family: 'ChiRho';
  padding: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center center fixed;
  background-size: cover;
}

.App {
  text-align: center;
}

.header {
  padding: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.left-section {
  width: 30%;
  padding-right: 20px;
  color: #898989;
  font-family: Times New Roman;
  font-size: 36px;
}

textarea {
  width: 100%;
  height: 200px;
}

.middle-section {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 2px;
}


#circleCanvas {
  height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,gFAA0E;EAC1E;4DAC0D;AAC5D;AACA;EACE,SAAS;EACT,qBAAqB;EACrB,UAAU;EACV,iFAAyE;EACzE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,cAAc;EACd,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;AACb;;;AAGA;EACE,aAAa;AACf","sourcesContent":["\n@font-face {\n  font-family: 'ChiRho';\n  src: local('ChiRho'), url(static/fonts/Constantine.ttf) format('truetype');\n  /* other formats include: 'woff2', 'truetype, 'opentype',\n                            'embedded-opentype', and 'svg' */\n}\nbody {\n  margin: 0;\n  font-family: 'ChiRho';\n  padding: 0;\n  background: url('static/images/marcus.jpg') no-repeat center center fixed;\n  background-size: cover;\n}\n\n.App {\n  text-align: center;\n}\n\n.header {\n  padding: 20px;\n}\n\n.content {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding: 20px;\n}\n\n.left-section {\n  width: 30%;\n  padding-right: 20px;\n  color: #898989;\n  font-family: Times New Roman;\n  font-size: 36px;\n}\n\ntextarea {\n  width: 100%;\n  height: 200px;\n}\n\n.middle-section {\n  display: flex;\n  flex-wrap: wrap;\n  width: 65%;\n}\n\n.circle {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: #ccc;\n  margin: 2px;\n}\n\n\n#circleCanvas {\n  height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
